import React, { useRef, useEffect, FC, ReactElement } from 'react';
import { Link, graphql } from 'gatsby';
import { formatPostDate, formatReadingTime } from '../../utils/helpers';
import Triangle from '../../components/Triangle';
import Section from '../../components/Section';
import theme from '../../theme';
import { ThemeProvider } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

import Bio from '../../components/Bio';
import BlogListLayout from '../../components/BlogListLayout';
import SEO from '../../components/SEO';
import Header from '../../components/BlogListHeader';
import './style.css';

interface FrontmatterObject {
  title: string;
  spoiler: string;
  slug: string;
  date: string;
}

interface PageObject {
  frontmatter: FrontmatterObject;
}

interface PageContext {
  previous: PageObject;
  next: PageObject;
  langKey: string;
}

interface MarkdownObject {
  timeToRead: string;
  frontmatter: FrontmatterObject;
  html: string;
}

interface Post {
  frontmatter: FrontmatterObject;
  timeToRead: string;
}

interface NodesObject {
  nodes: Post[];
}

interface DataObject {
  markdownRemark: MarkdownObject;
  allMarkdownRemark: NodesObject;
}

interface LocationObject {
  frontmatter: FrontmatterObject;
  html: string;
  pathname?: string;
}

type ChildProps = {
  data: DataObject;
  location: LocationObject;
  pageContext: PageContext;
};

const BlogListTemplate: FC<ChildProps> = ({
  pageContext,
  data,
  location,
}): ReactElement => {
  const scrollTopRef = useRef<HTMLButtonElement>(null);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleScroll = () => {
    if (
      (document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20) &&
      scrollTopRef.current !== null
    ) {
      scrollTopRef.current.style.display = 'block';
    } else {
      if (scrollTopRef.current !== null)
        scrollTopRef.current.style.display = 'none';
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const siteTitle = 'Writings by Ajay';
  const langKey = pageContext.langKey;

  let posts = data.allMarkdownRemark.nodes;
  posts = posts
    .filter((post) => post.frontmatter.title)
    .sort(
      (a, b) =>
        new Date(b.frontmatter.date).valueOf() -
        new Date(a.frontmatter.date).valueOf(),
    );

  return (
    <ThemeProvider theme={theme}>
      <Header
      // theme={theme}
      />

      <Section.Container id="home" Background={Background}>
        <BlogListLayout location={location} title={siteTitle}>
          <SEO />
          <main>
            {posts.map((post, index) => {
              const { title, slug, spoiler, date } = post.frontmatter;

              return (
                <article key={index}>
                  <header>
                    <h3
                      style={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: '1.75rem',
                        marginBottom: '0.4375rem',
                      }}
                    >
                      <Link
                        style={{ boxShadow: 'none', color: 'rgb(9, 1, 95)' }}
                        to={'/writing/' + slug}
                        rel="bookmark"
                        className="blog-link"
                      >
                        {title}
                      </Link>
                    </h3>
                    <small>
                      {formatPostDate(date, langKey)}
                      {` • ${formatReadingTime(post.timeToRead)}`}
                    </small>
                  </header>
                  <p dangerouslySetInnerHTML={{ __html: spoiler }} />
                </article>
              );
            })}
          </main>
          {/* <Footer /> */}
          <button
            onClick={scrollToTop}
            ref={scrollTopRef}
            className="scroll-to-top"
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </button>
        </BlogListLayout>
      </Section.Container>
    </ThemeProvider>
  );
};

export default BlogListTemplate;

const Background = () => (
  <>
    <Triangle
      color="muted"
      theme={theme}
      height={['35vh', '80vh']}
      width={['95vw', '60vw']}
    />

    <Triangle
      color="secondary"
      theme={theme}
      height={['38vh', '80vh']}
      width={['50vw', '35vw']}
    />

    <Triangle
      color="primary"
      theme={theme}
      height={['25vh', '35vh']}
      width={['75vw', '60vw']}
      position="top-right"
    />

    <Triangle
      color="muted"
      theme={theme}
      height={['20vh', '20vh']}
      width={['100vw', '100vw']}
      position="bottom-right"
    />
  </>
);

export const pageQuery = graphql`
  query MyQuery {
    allMarkdownRemark(filter: { frontmatter: { spoiler: {} } }) {
      nodes {
        timeToRead
        frontmatter {
          spoiler
          slug
          title
          date
        }
      }
    }
  }
`;
