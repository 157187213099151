import React from 'react';
import Headroom from 'react-headroom';
import { Box, Button, Flex, Image } from 'rebass/styled-components';
import styled from 'styled-components';
import { Link as NavLink} from "gatsby";
import { useHelmetQuery } from '../queries/useHelmetQuery';
import {navigate} from 'gatsby';

const Header = () => {
  return (
    <StyledHeadroom>
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        px={3}
      >
        <HomeLink />
        <Flex mr={[0, 3, 5]}>
         
            <Box ml={[2, 3]} color="background" fontSize={[2, 3]}>
              <NavLink to="/#about" tabIndex={0} className="Link-cjgSXo jScxCJ">
                {"About"}
              </NavLink>
            </Box>
            <Box ml={[2, 3]} color="background" fontSize={[2, 3]}>
              <NavLink to="/#projects" tabIndex={0} className="Link-cjgSXo jScxCJ">
                {"Projects"}
              </NavLink>
            </Box>
            <Box ml={[2, 3]} color="background" fontSize={[2, 3]}>
              <NavLink to="/writings" tabIndex={0} className="Link-cjgSXo jScxCJ">
                {"Writings"}
              </NavLink>
            </Box>
        </Flex>
      </Flex>
    </StyledHeadroom>
  );
};

const HomeLink = () => {
  const { profile } = useHelmetQuery();
  // const { onClick } = useScrollSection('home');
  
  const onclick =() => navigate("/")
  return (
    <Button onClick={onclick} variant="empty">
      <Flex justifyContent="center">
        <Image
          src={profile.bigIcon.src}
          height={['60px', '80px']}
          width={['60px', '80px']}
          alt="Portfolio Logo"
          p={2}
          css={{ borderRadius: '20px', cursor: 'pointer' }}
        />
      </Flex>
    </Button>
  );
};

const StyledHeadroom = styled(Headroom)`
  * {
    transition: background-color 0.1s ease;
  }

  .headroom--pinned {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  position: absolute;
  width: 100%;
`;

export default Header;
